import React from "react";
import '../../../assets/css/formpagerecrut.css';
import { useTranslation } from "react-i18next";

export default function FormContactRecrut(props){

    const { t } = useTranslation();

    function toggle() {
        document.getElementById("collapserecrut").classList.toggle("hide");
    }

    return(


        <div className="formrecrut-container">
            <input type="button" value={t('pages.career.contact.button')} className="formcontact-button" onClick={toggle}/>

            <div id="collapserecrut" className="show">

                <div className="formrecrut-form">

                    <form action="https://formsubmit.co/recrutement@camondoconsulting.fr" method="POST" encType="multipart/form-data" name="pagerecrut">

                        <input type="hidden" name="_subject" value={props.subject} />
                        <input type="hidden" name="_autoresponse" value={t('pages.career.contact.mailtext')}/>
                        <input type="hidden" name="_template" value="box"/>


                        <div className="formrecrut-form-select">
                
                            <div className="formrecrut-form-select job">
                    
                                <select id="page-recrut-form-select-job" name="Job">
                                    <option value="choix poste">{t('pages.career.contact.select.title')}</option>
                                    <option value="consultant">{t('pages.career.contact.select.option1')}</option>
                                    <option value="profil confirmé">{t('pages.career.contact.select.option2')}</option>
                                    <option value="stagiaire/alternant">{t('pages.career.contact.select.option3')}</option>
                                </select>

                            </div>

                        </div>

                        <div className="formrecrut-form-row">
                  
                            <div class="input-data" id="formFirstName">
                                <input type="text" name="Firstname" required/>
                                <div class="underline"></div>
                                <label for="">{t('pages.contactus.labels.label1')}</label>
                            </div>

                            <div class="input-data" id="formLastName">
                                <input type="text" name="Lastname" required/>
                                <div class="underline"></div>
                                <label for="">{t('pages.contactus.labels.label2')}</label>
                            </div>
                            
                        </div>

                        <div className="formrecrut-form-row">

                            <div class="input-data" id="formPhone">
                                <input type="text" name="Phone" required/>
                                <div class="underline"></div>
                                <label for="">{t('pages.contactus.labels.label4')}</label>
                            </div>

                            <div class="input-data" id="formMail">
                                <input type="email" name="email" required/>
                                <div class="underline"></div>
                                <label for="">{t('pages.contactus.labels.label3')}</label>
                            </div>


                        </div>

                        <div className="formrecrut-form-row" id="formMessage">

                            <div className="input-data textarea">
                                <textarea rows="20" cols="80" name="message" required/>
                                <div className="underline"></div>
                                <label for="">{t('pages.contactus.labels.label7')}</label>
                            </div>

                        </div>

                        <div className="pagerecrut-form-row" id="formFile">

                            <div className="input-data">
                                <input type="file" name="attachment" accept=".pdf" required/>
                                <p>{t('pages.contactus.labels.label11')}</p>
                            </div>

                        </div>

                        <div className="formrecrut-form-row" id="formSubmit">

                            <input type="submit" value={t('pages.career.contact.send')} className="formcontact-button"/>

                        </div>
                
                    </form>
                </div>
            </div>
        </div>

    )
}