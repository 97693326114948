import React from "react";
import '../../../assets/css/cardspageexpertises.css';
import { useTranslation } from "react-i18next";
import BA from "../../../assets/img/logos/data-analysis(1).png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

import Alteryx from "../../../assets/img/partnerslogos/Alteryx_Logo.png"
import Dataiku from "../../../assets/img/partnerslogos/Dataiku_logo.png"
import PowerBI from "../../../assets/img/partnerslogos/PowerBI-Logo.png"
import Tableau from "../../../assets/img/partnerslogos/Tableau_Logo.png"
import QlikSense from "../../../assets/img/partnerslogos/QlikSense_Logo.png"

export default function CardsBusinessAnalytics(){

    const { t } = useTranslation();

    return(

        <div className="cardspageexpertises-container" id="BA">

            <div className="cardspageexpertises-cards cardsanimation">

                <div className="cardspageexpertises-cardsheader">
                    <img src={BA } className="pageexpertises-logo" alt="icon made by surang from flaticon"></img>
                    
                    <h1>{t('pages.expertises.expertise1.sub-expertise3.title')}</h1>

                </div>

                <div className="cardspageexpertises-cardsbody">

                    <div className="cardspageexpertises-cardsbody-text">

                        <p>{t('pages.expertises.expertise1.sub-expertise3.parttext.text1')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise1.sub-expertise3.parttext.text2')}</p>
                        <p>{t('pages.expertises.expertise1.sub-expertise3.parttext.text3')}</p>
                        <br/>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise3.contact.text1')}</strong>{t('pages.expertises.expertise1.sub-expertise3.contact.text2')}</p>
                        <br/>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Business Analytics"/>
                        </div>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise3.parttext.text6')}</strong></p>
                        
                        <div className="cardspageexpertises-cardsbody-check-logo">
                            <img className="pageexpertises-check-logo" src={Alteryx} alt="logo Alteryx"/>
                            <img className="pageexpertises-check-logo" src={Dataiku} alt="logo Dataiku"/>
                            <img className="pageexpertises-check-logo" src={PowerBI} alt="logo Power BI"/>
                            <img className="pageexpertises-check-logo" src={Tableau} alt="logo Tableau"/>
                            <img className="pageexpertises-check-logo" src={QlikSense} alt="logo QlikSense"/>
                            <p>. . .</p>
                        </div>

                        <br/>
                        <p>
                            <strong>{t('pages.expertises.expertise3.sub-expertise1.keywords.title')}</strong>
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword1')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword2')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword3')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword4')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword5')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword6')}
                        </p>

                    </div>

                    

                </div>
                
            </div>

            <div className="cardspageexpertises-seperate"></div>

        </div>

    )

}