import React from "react";
import '../../../assets/css/cardspagepractices.css';
import { useTranslation } from "react-i18next";
import assets from "../../../assets/img/logos/assets.png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

export default function CardsAssetManagament(){

    const { t } = useTranslation();

    return(

        <div className="cardspagepractices-container" id="AM">

            <div className="cardspagepractices-cards cardsanimation">

                <div className="cardspagepractices-cardsheader">

                    <img src={assets} className="pagepractices-logo" alt="icon made by zulfa-mahendra from flaticon"></img>
                    <h1>{t('pages.practices.practice1.sub-practice1.title')}</h1>

                </div>

                <div className="cardspagepractices-cardsbody">

                    <div className="cardspagepractices-cardsbody-text1">

                        <p>{t('pages.practices.practice1.sub-practice1.parttext.text1')}</p>
                        <br/>
                        <ul className="cardspagepractices-cardsbody-textlist">

                            <li>{t('pages.practices.practice1.sub-practice1.parttext.text2')}</li>
                            <li>{t('pages.practices.practice1.sub-practice1.parttext.text3')}</li>
                            <li>{t('pages.practices.practice1.sub-practice1.parttext.text4')}</li>
                            <li>{t('pages.practices.practice1.sub-practice1.parttext.text5')}</li>
                            <li>{t('pages.practices.practice1.sub-practice1.parttext.text6')}</li>
                            <li>{t('pages.practices.practice1.sub-practice1.parttext.text7')}</li>

                        </ul>

                        
                        <div className="clear"></div>
                        <br/>
                        <p><strong>{t('pages.practices.practice1.sub-practice1.contact.text1')}</strong>{t('pages.practices.practice1.sub-practice1.contact.text2')}</p>
                        <div className="clear"></div>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Asset Management"/>
                        </div>
                        <br/>

                    </div>
                    
                    

                </div>
                
            </div>

            <div className="cardspagepractices-seperate"></div>

        </div>

    )

}