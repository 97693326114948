import {React} from "react";
import "../../assets/css/body.css"

import Domaines from "./Domaines/Domaine";
import JoinC from "./Join/Join";
import ContactUs from "./Contact/ContactUs";
import ClientsLogosSlider from "./ClientsLogos/ClientsLogosSlider";
import CounterNumber from "./Counter/CounterNumber";


export default function BodyContent(){


    return(

        <div className="body-body">
            <ClientsLogosSlider/>

            <Domaines/>
            <CounterNumber/>
            <ContactUs/>
            <JoinC/>

        </div>


    )

}