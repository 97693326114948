import React from "react";
import $ from "jquery";
import "../assets/css/pagecontactus.css"
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer/Footer";
import NavbarContactus from "../components/Header/NavbarContactus";


export default function PageContact() {

  const { t } = useTranslation();


  $('#page-contactus-form-select-menu').on('change',function(){
    if($(this).val()==="partenaire" || $(this).val()==="autre" || $(this).val()==="sujet"){
      $("#formSocietyFunction").show()
      $("input#SocietyFunction").attr("required")
    }
    else if($(this).val()==="postuler"){
      $("#formSocietyFunction").hide()
      $("input#SocietyFunction").removeAttr("required")
    }
    else{
      $("#formSocietyFunction").hide()
      $("input#SocietyFunction").removeAttr("required")
    }
  })



  return (
    <div className="pagecontactus" id="contactus">

      <NavbarContactus/>

      <div className="pagecontactus-container">

        <div className="pagecontactus-container-body">

          <div className="pagecontactus-title">
            <h1>{t('pages.contactus.title')}</h1>
          </div>

          <div className="pagecontactus-form">

            <form action="https://formsubmit.co/info@camondoconsulting.fr" method="POST" encType="multipart/form-data" name="pagecontact">

                <input type="hidden" name="_subject" value="Formulaire page contact" />
                <input type="hidden" name="_autoresponse" value={t('pages.contactus.mailtext')}/>
                <input type="hidden" name="_template" value="box"/>


              <div className="pagecontactus-form-select">
                
                <label for="">{t('pages.contactus.select.select1.title')}</label>
                <select id="page-contactus-form-select-menu" name="Sujet" required>
                  <option value="sujet">{t('pages.contactus.select.select1.option1')}</option>
                  <option value="partenaire">{t('pages.contactus.select.select1.option2')}</option>
                  <option value="autre">{t('pages.contactus.select.select1.option4')}</option>
                </select>

              </div>

              <div className="pagecontactus-form-row">
                  
                <div class="input-data" id="formFirstName">
                  <input type="text" name="Firstname" required/>
                  <div class="underline"></div>
                  <label for="">{t('pages.contactus.labels.label1')}</label>
                </div>

                <div class="input-data" id="formLastName">
                  <input type="text" name="Lastname" required/>
                  <div class="underline"></div>
                  <label for="">{t('pages.contactus.labels.label2')}</label>
                </div>
                  
              </div>

              <div class="pagecontactus-form-row">

                <div class="input-data" id="formMail">
                  <input type="email" name="email" required/>
                  <div class="underline"></div>
                  <label for="">{t('pages.contactus.labels.label3')}</label>
                </div>

                <div class="input-data" id="formPhone">
                  <input type="text" name="Phone" required/>
                  <div class="underline"></div>
                  <label for="">{t('pages.contactus.labels.label4')}</label>
                </div>

              </div>

              <div class="pagecontactus-form-row" id="formSocietyFunction">

                <div class="input-data" id="formSociety">
                  <input type="text" name="Company" id="SocietyFunction" required/>
                  <div class="underline"></div>
                  <label for="">{t('pages.contactus.labels.label5')}</label>
                </div>

              </div>

              <div className="pagecontactus-form-row" id="formWhere">

                <div className="input-data" id="formCity">
                  <input type="text" name="city" required/>
                  <div className="underline"></div>
                  <label for="">{t('pages.contactus.labels.label9')}</label>
                </div>

                <div className="input-data" id="formPostCode">
                  <input type="text" name="Postcode" required/>
                  <div className="underline"></div>
                  <label for="">{t('pages.contactus.labels.label10')}</label>
                </div>

              </div>

              <div className="pagecontactus-form-row" id="formWhere">

                <div className="input-data" id="formCountry">
                  <input type="text" name="Country" required/>
                  <div className="underline"></div>
                  <label for="">{t('pages.contactus.labels.label8')}</label>
                </div>

              </div>


              <div className="pagecontactus-form-row" id="formMessage">

                <div className="input-data textarea">
                  <textarea rows="20" cols="80" name="Message" required/>
                  <div className="underline"></div>
                  <label for="">{t('pages.contactus.labels.label7')}</label>
                </div>

              </div>

              <div className="pagecontactus-form-row" id="formFile">

                <div className="input-data">
                  <input type="file" name="Attachment" accept=".pdf" />
                </div>

              </div>

              <div className="pagecontactus-form-row" id="formSubmit">

                <input type="submit" name="submit" value={t('pages.expertises.expertise3.contact.envoyer')} className="formcontact-button input-send"/>

              </div>


            </form>

          </div>

        </div>
        
      </div>

      <Footer/>


    </div>
        

  );
}

