import {React} from "react";
import "../../../assets/css/cardsexpertises.css";
import { useTranslation } from "react-i18next";

import right from "../../../assets/img/icons/right.png"

export default function CardsExpertise(props){

    const { t } = useTranslation();

    return(

        <div className="cards-expertises">
            <div className="cards-expertises-container">
                <div className="cards-expertises-container1">
                    <div className="cards-expertises-title">
                        <h2>
                            {props.title}
                        </h2>
                    </div>
                    <div className="cards-expertises-logos">
                        <img src={props.img} className="cards-expertises-logo" alt="logo expertises"></img>
                    </div>
                </div>
                <div className="cards-expertises-texts">
                    <p>
                        {props.text}
                    </p>
                </div>

                <div className="cards-expertises-readmore">
                    <p>{t('body.readmore')}</p>
                    <img src={right} alt="logo email" className="icons"/>
                </div>
            </div>
        </div>

    )

}