import React from "react";
import '.././assets/css/pageexpertisespractices.css';
import { useTranslation } from "react-i18next";
import NavbarExpertises from "../components/Header/NavbarExpertisesDE";
import Footer from "../components/Footer/Footer";


import DI from "../assets/img/logos/digital-experience-1.png"

import { Outlet } from "react-router-dom";
import CardsDigitalIntelligence from "../components/Body/PageExpertise2DE/DigitalIntelligence";


export default function PageExpertiseDigitalIntelligence() {

    const { t } = useTranslation();

    return (

        <div className="maincontainer-pageexpertises" id="PageDI">
            
            <NavbarExpertises/>
            
            <div className="container-pageexpertises">

                <div className="header-pageexpertises">

                    <img src={DI} className="pageexpertises-headerlogo" alt="icon made by iconixar from flaticon"></img>
                    <h1>{t('pages.expertises.expertise2.title')}</h1>

                    <div className="header-pageexpertises-text">
                        <p>{t('pages.expertises.expertise2.text')}</p>
                    </div>

                </div>

                <CardsDigitalIntelligence/>


            </div>

            <Outlet/>

            <Footer/>

        </div>
        

    );
}

