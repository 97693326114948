import React from "react";
import "../../../assets/css/clientsslider.css";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

import LVMH from "../../../assets/img/clientslogos/LVMH-logo2.png";
import HERMES from "../../../assets/img/clientslogos/Hermes-logo2.png";
import BNP from "../../../assets/img/clientslogos/BNP-Paribas.png";
import Veolia from "../../../assets/img/clientslogos/RGB_VEOLIA_HD.png";
import LesMousquetaires from "../../../assets/img/clientslogos/Groupe_Les_Mousquetaires.svg.png";
import Alstom from "../../../assets/img/clientslogos/Alstom_Logo.png";
import LCL from "../../../assets/img/clientslogos/LCL_Logo.png";
import Chanel from "../../../assets/img/clientslogos/Chanel_Logo.png";
import Hanagroup from "../../../assets/img/clientslogos/Hanagroup_Logo.png";
import Meeschaert from "../../../assets/img/clientslogos/Messchaert_Logo.png";
import Axa from "../../../assets/img/clientslogos/Axa_Logo.png";
import SocieteGenerale from "../../../assets/img/clientslogos/logo-societe-generale.png";
import CreditAgricole from "../../../assets/img/clientslogos/CreditAgricole_Logo.png";
import Cardif from "../../../assets/img/clientslogos/Cardif-BNPGroup_Logo.png";
import FranceMess from "../../../assets/img/clientslogos/FranceMessagerie_Logo.png";
import Safran from "../../../assets/img/clientslogos/Safran_Logo.png";
import Bauer from "../../../assets/img/clientslogos/Bauer_Logo.png";
import Unimedia from "../../../assets/img/clientslogos/Unimédias_Logo.png";
import Gobain from "../../../assets/img/clientslogos/StGobain_Logo.png";

import Oracle from "../../../assets/img/partnerslogos/Oracle-Logo.png";
import Microsoft from "../../../assets/img/partnerslogos/Microsoft_Logo.png";
import IBM from "../../../assets/img/partnerslogos/IBM_Logo.png";
import SAP from "../../../assets/img/partnerslogos/SAP_2011_logo.svg.png";
import Salesforce from "../../../assets/img/partnerslogos/Salesforce_Logo.png";
import Tibco from "../../../assets/img/partnerslogos/Tibco_Logo.png";
import Semarchy from "../../../assets/img/partnerslogos/Semarchy_Logo.png";
import Onestream from "../../../assets/img/partnerslogos/OnestreamLogo.jpg";
import Anaplan from "../../../assets/img/partnerslogos/Anaplan-logo.png";
import Tableau from "../../../assets/img/partnerslogos/Tableau_Logo.png";
import Tagetik from "../../../assets/img/partnerslogos/CCH_Tagetik_Logo.png";
import Talend from "../../../assets/img/partnerslogos/Talend_Logo.png";
import Teradata from "../../../assets/img/partnerslogos/Teradata_logo.png";
import Alteryx from "../../../assets/img/partnerslogos/Alteryx_Logo.png"
import Dataiku  from "../../../assets/img/partnerslogos/Dataiku_logo.png"
import Snowflake from "../../../assets/img/partnerslogos/Snowflake_Logo.svg.png"
import Board from "../../../assets/img/partnerslogos/Board_Logo.png"
import QlikSense from "../../../assets/img/partnerslogos/QlikSense_Logo.png"


export default function ClientsLogosSlider(){

    const { t } = useTranslation();

    const imgClients = [
        {src:LVMH, title:"logo LVMH"},
        {src:HERMES, title:"logo hermes"},
        {src:BNP, title:"logo BNP Paribas"},
        {src:Veolia, title:"logo BNP Paribas"},
        {src:LesMousquetaires, title:"logo BNP Paribas"},
        {src:Alstom, title:"logo BNP Paribas"},
        {src:LCL, title:"logo LCL"},
        {src:Chanel, title:"logo Chanel"},
        {src:Hanagroup, title:"logo Hanagroup"},
        {src:Meeschaert, title:"logo Meeschaert"},
        {src:Axa, title:"logo Axa"},
        {src:SocieteGenerale, title:"logo Société Générale"},
        {src:CreditAgricole, title:"logo Crédit Agricole"},
        {src:Cardif, title:"logo BNP Cardif"},
        {src:FranceMess, title:"logo France Messagerie"},
        {src:Safran, title:"logo Safran"},
        {src:Bauer, title:"logo Bauer"},
        {src:Unimedia, title:"logo Unimedia"},
        {src:Gobain, title:"logo France Messagerie"},
    ];

    const imgPartners=[
        {src:Oracle, title:"logo Oracle"},
        {src:Microsoft, title:"logo Microsoft"},
        {src:IBM, title:"logo IBM"},
        {src:SAP, title:"logo SAP"},
        {src:Salesforce, title:"logo Salesforce"},
        {src:Tableau, title:"logo Tableau Software"},
        {src:Tibco, title:"logo Tibco"},
        {src:Semarchy, title:"logo Semarchy"},
        {src:Onestream, title:"logo Onestream"},
        {src:Anaplan, title:"logo Anaplan"},
        {src:Tagetik, title:"logo CCH Tagetik"},
        {src:Talend, title:"logo Talend"},
        {src:Teradata, title:"logo Teradata"},
        {src:Alteryx, title:"logo Alteryx"},
        {src:Dataiku, title:"logo Dataiku"},
        {src:Snowflake, title:"logo Snowflake"},
        {src:Board, title:"logo Board International"},
        {src:QlikSense, title:"logo QlikSense"},
    ]

    return(

        <div className="clientsslider-body" id="clientspartners">
            <div className="clientsslider-header">
                <div className="clientsslider-header-line"></div>
                <h2 id="clientsslider-titre">
                    {t('body.clients.title')}
                </h2>
                <div className="clientsslider-header-line"></div>
            </div>
            <div className="clientsslider-logos">
                <h2 id="clientsslider-titre2">
                    {t('body.clients.title2')}
                </h2>
                <Marquee className="clientsslider-marquee">
                    <div className="clientsslider-display">
                        {
                            imgClients.map((index) => <img src={index.src} alt={index.title} className="img-clientsslider" id="white-clientslider-logo"/>)
                            
                        }
                        {
                            imgClients.map((index) => <img src={index.src} alt={index.title} className="img-clientsslider" id="white-clientslider-logo"/>)
                            
                        }
                    </div>
                </Marquee>
                <h2 id="clientsslider-titre2">
                    {t('body.clients.title3')}
                </h2>
                <Marquee direction="right" className="partnersslider-marquee">
                    <div className="partnersslider-display">
                        {
                            imgPartners.map((index) => <img src={index.src} alt={index.title} className="img-clientsslider" id="white-clientslider-logo"/>)
                            
                        }
                        {
                            imgPartners.map((index) => <img src={index.src} alt={index.title} className="img-clientsslider" id="white-clientslider-logo"/>)
                            
                        }
                    </div>
                </Marquee>
            </div>
            
            

            {/*<p id="clientsslider-infos">{t('body.clients.info')}</p>*/}
            
        </div>

    );

}