import React from "react";
import '../../../assets/css/cardspageexpertises.css';
import { useTranslation } from "react-i18next";
import BI from "../../../assets/img/logos/DataGovernance.png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

import Tibco from "../../../assets/img/partnerslogos/Tibco_Logo.png"
import Semarchy from "../../../assets/img/partnerslogos/Semarchy_Logo.png"

export default function CardsDataGovernance(){

    const { t } = useTranslation();

    return(

        <div className="cardspageexpertises-container" id="DG">

            <div className="cardspageexpertises-cards cardsanimation">

                <div className="cardspageexpertises-cardsheader">

                    <img src={BI} className="pageexpertises-logo" alt="icon made by Ida Desi Mariana from flaticon"></img>
                    <h1>{t('pages.expertises.expertise1.sub-expertise1.title')}</h1>

                </div>

                <div className="cardspageexpertises-cardsbody">

                    <div className="cardspageexpertises-cardsbody-text">

                        <p>{t('pages.expertises.expertise1.sub-expertise1.parttext.text1')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise1.sub-expertise1.parttext.text2')}</p>
                        <br/>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise1.contact.text1')}</strong>{t('pages.expertises.expertise1.sub-expertise1.contact.text2')}</p>
                        <br/>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Data Governance"/>
                        </div>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise1.parttext.text3')}</strong></p>

                        
                        <div className="cardspageexpertises-cardsbody-check-logo">
                            <img className="pageexpertises-check-logo" src={Tibco} alt="logo Tibco"/>
                            <img className="pageexpertises-check-logo" src={Semarchy} alt="logo Semarchy"/>
                            <p>. . .</p>
                        </div>

                        <br/>
                        <p>
                            <strong>{t('pages.expertises.expertise3.sub-expertise1.keywords.title')}</strong>
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword1')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword2')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword3')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword4')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword5')}
                            {t('pages.expertises.expertise1.sub-expertise1.keywords.keyword6')}
                        </p>

                    </div>

                    

                </div>
                
            </div>

            <div className="cardspageexpertises-seperate"></div>

        </div>

    )

}