import React from "react";
import '.././assets/css/pageexpertisespractices.css';
import { useTranslation } from "react-i18next";
import NavbarPractices from "../components/Header/NavbarPracticesPB";
import Footer from "../components/Footer/Footer";
import BarSelectPracticePB from "../components/Body/PagePractice1PB/BarSelectPractices1";

import DI from "../assets/img/logos/bank.png"

import { Outlet } from "react-router-dom";



export default function PagePracticePB() {

    const { t } = useTranslation();

    return (

        <div className="maincontainer-pageexpertises" id="PagePB">
            
            <NavbarPractices/>
            
            <div className="container-pageexpertises">

                <div className="header-pageexpertises">

                    <img src={DI} className="pageexpertises-headerlogo" alt="icon made by freepik from flaticon"></img>
                    <h1>{t('pages.practices.practice1.title')}</h1>

                    <div className="header-pageexpertises-text">
                        <p>{t('pages.practices.practice1.text')}</p>
                    </div>

                </div>

                <div className="body-pageexpertises">
                    <BarSelectPracticePB/>
                </div>


            </div>

            <Outlet/>

            <Footer/>

        </div>
        

    );
}

