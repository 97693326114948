import React from "react";
import "../../../assets/css/counternumber.css";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";



function Number({n}){
    const {number} = useSpring({
        from: { number : 0},
        number: n,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 15},
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>
}

function NumberDec({n}){
    const {number} = useSpring({
        from: { number : 0},
        number: n,
        delay: 200,
        config: { mass: 0.5, tension: 12, friction: 7.5},
    });
    return <animated.div>{number.to((n) => n.toFixed(0.5))}</animated.div>
}


export default function CounterNumber(){

    const { t } = useTranslation();

    return(
        <div className="counternumber-body">
            <h2>{t('body.numbers.title')}</h2>

            <div className="counternumber-container">
                <div className="counternumber-numberstitle">
                    <div className="counternumber-numberstitle-numbers">
                        <p>+ </p>
                        <p className="counternumber-numberstitle-number"><Number n={40}/></p>
                        <p></p>
                    </div>
                    <p className="counternumber-numberstitle-title">{t('body.numbers.number1')}</p>
                </div>

                <div className="counternumber-numberstitle">
                    <div className="counternumber-numberstitle-numbers">
                        <p>~ </p>
                        <p className="counternumber-numberstitle-number"><NumberDec n={4}/></p>
                        <p> M €</p>
                    </div>
                    <p className="counternumber-numberstitle-title">{t('body.numbers.number2')}</p>
                </div>

                <div className="counternumber-numberstitle">
                    <div className="counternumber-numberstitle-numbers">
                        <p>+ </p>
                        <p className="counternumber-numberstitle-number"><Number n={20}/></p>
                    </div>
                    <p className="counternumber-numberstitle-title">{t('body.numbers.number3')}</p>
                </div>

                <div className="counternumber-numberstitle">
                    <div className="counternumber-numberstitle-numbers">
                        <p>+ </p>
                        <p className="counternumber-numberstitle-number"><Number n={15}/></p>
                    </div>
                    <p className="counternumber-numberstitle-title">{t('body.numbers.number4')}</p>
                </div>
            </div>
        </div>

    )


}