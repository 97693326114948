import React from 'react'
import '../../../assets/css/cardspagepractices.css';
import { useTranslation } from "react-i18next";
import FormContactExpPrac from '../Contact/ContactExpertisesPractices';

export default function CardsDiffNum() {

    const { t } = useTranslation();

  return (
    <div className="cardspagepractices-container" id="DiffusionNumérique">

        <div className="cardspagepractices-cards cardsanimation">

            <div className="cardspagepractices-cardsheader">

                <h1>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.title')}</h1>

            </div>

            <div className="cardspagepractices-cardsbody-presse">

                <div className="cardspagepractices-cardsbody-presse-text">

                    <div className="cardspagepractices-cardsbody-presse-text-text">

                        <p>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.text1')}</p>
                        <div className='clear'></div>
                        <p>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.text2')}</p>

                    </div>

                </div>

                    <div className="cardspagepractices-cardsbody-presse-text">

                        <ul>
                            <li>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.textlist.textlist1')}</li>
                            <li>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.textlist.textlist2')}</li>
                            <li>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.textlist.textlist3')}</li>
                        </ul>

                </div>

                <div className="form-practice-presse">
                    <div className="clear"></div>
                    <br/>
                    <p><strong>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.contact.text1')}</strong>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP3.contact.text2')}</p>
                    <div className="clear"></div>
                    <div className="pageexpertises-forminfo">
                        <FormContactExpPrac subject="Formulaire Diffusion Numérique"/>
                    </div>
                    <br/>
                </div>

                <div className='cardspagepractices-cardsbody-presse-text'>
                    <div className="cardspagepractices-cardsbody-presse-text-text">
                        <p>{t('pages.practices.practicePresse.sub-practicesPresse.footer.text1')}</p>
                        <div className='clear'></div>
                        <p>{t('pages.practices.practicePresse.sub-practicesPresse.footer.text2')}</p>
                    </div>
                </div>

            </div>

        </div>

    </div>
  )
}
