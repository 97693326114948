import React from "react";
import '../../../assets/css/cardspagepractices.css';
import { useTranslation } from "react-i18next";
import conforme from "../../../assets/img/logos/conforme.png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

export default function CardsCompliance(){

    const { t } = useTranslation();

    return(

        <div className="cardspagepractices-container" id="C">

            <div className="cardspagepractices-cards cardsanimation">

                <div className="cardspagepractices-cardsheader">

                    <img src={conforme} className="pagepractices-logo" alt="icon made by freepik from flaticon"></img>
                    <h1>{t('pages.practices.practice1.sub-practice2.title')}</h1>

                </div>

                <div className="cardspagepractices-cardsbody">

                    <div className="cardspagepractices-cardsbody-text1">

                        <p>{t('pages.practices.practice1.sub-practice2.parttext.text1')}</p>
                        <br/>
                        <br/>
                        <p>{t('pages.practices.practice1.sub-practice2.parttext.textlist.text')}</p>
                        <ul className="cardspagepractices-cardsbody-textlist">

                            <li>{t('pages.practices.practice1.sub-practice2.parttext.textlist.textlist1')}</li>
                            <li>{t('pages.practices.practice1.sub-practice2.parttext.textlist.textlist2')}</li>
                            <li>{t('pages.practices.practice1.sub-practice2.parttext.textlist.textlist3')}</li>
                            <li>{t('pages.practices.practice1.sub-practice2.parttext.textlist.textlist4')}</li>
                            <li>{t('pages.practices.practice1.sub-practice2.parttext.textlist.textlist5')}</li>
                            <li>{t('pages.practices.practice1.sub-practice2.parttext.textlist.textlist6')}</li>
                            <li>{t('pages.practices.practice1.sub-practice2.parttext.textlist.textlist7')}</li>

                        </ul>

                        <div className="clear"></div>
                        <br/>
                        <p><strong>{t('pages.practices.practice1.sub-practice2.contact.text1')}</strong>{t('pages.practices.practice1.sub-practice2.contact.text2')}</p>
                        <div className="clear"></div>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Compliance"/>
                        </div>
                        <br/>

                    </div>

                </div>
                
            </div>

            <div className="cardspagepractices-seperate"></div>

        </div>

    )

}