import {React} from "react";
import "../../../assets/css/contactus.css";
import { useTranslation } from "react-i18next";
import email from "../../../assets/img/icons/email-logo.png";
import phone from "../../../assets/img/icons/phone-logo-1.png"

export default function ContactUs(){

    const { t } = useTranslation();

    return(

        <div className="contactus-body" id="contactus">

            <h2 id="contactus-titre">
                {t('body.contact.title')}
            </h2>
            <p id="contactus-text">
                {t('body.contact.text')}
            </p>
            
            <div id="contactus-icons">
                <div className="contactus-icon">
                    <img src={phone} alt="logo phone" className="icons"/>
                    <p className="contactus-para">{t('body.contact.phone')}</p>
                </div>
                <div className="contactus-icon">
                    <img src={email} alt="logo email" className="icons"/>
                    <p className="contactus-para"><a href="mailto:info@camondoconsulting.fr">{t('body.contact.email')}</a></p>
                </div>
            </div>

        </div>

    )

}