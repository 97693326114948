import React from "react";
import '../../../assets/css/barselectexpertisespractices.css';
import { useTranslation } from "react-i18next";

import { HashLink } from "react-router-hash-link";

export default function BarSelectPracticePB(){

    const { t } = useTranslation();

    const linkExpertise = document.querySelectorAll('.boxexpertises_container_link');

    linkExpertise.forEach(link => {
        link.addEventListener('click', function() {
            document.querySelector('.activeexpertise')?.classList.remove('activeexpertise');
            this.classList.add('activeexpertise');
        })
    })

    const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return(

        <div className="barselectexpertises-container">

                <ul className="boxexpertises-maincontainer">

                    <li className="boxexpertises-container" id="expertises-button">
                        <HashLink smooth to="AssetManagement/#AM" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link" id="AssetManagement">
                            <h4>{t('pages.practices.practice1.sub-practice1.title')}</h4>
                        </HashLink>
                    </li>

                    <li className="boxexpertises-container" id="expertises-button">
                        <HashLink smooth to="Compliance/#C" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link" id="Compliance">
                            <h4>{t('pages.practices.practice1.sub-practice2.title')}</h4>
                        </HashLink>

                    </li>

                    <li className="boxexpertises-container" id="expertises-button">
                        <HashLink smooth to="CapitalMarket/#CM" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link" id="CapitalMarket" >
                            <h4>{t('pages.practices.practice1.sub-practice3.title')}</h4>
                        </HashLink>
                    </li>

                    <li className="boxexpertises-container" id="expertises-button">
                        <HashLink smooth to="SecuritiesServices/#SS" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link" id="SecuritiesServices">
                            <h4>{t('pages.practices.practice1.sub-practice4.title')}</h4>
                        </HashLink>
                    </li>

                    <li className="boxexpertises-container" id="expertises-button">
                        <HashLink smooth to="Finance&Risks/#F&R" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link" id="Finance&Risks">
                            <h4>{t('pages.practices.practice1.sub-practice5.title')}</h4>
                        </HashLink>
                    </li>

                </ul>

        </div>

    )

}