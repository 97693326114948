import React from "react";
import '.././assets/css/pageexpertisespractices.css';
import { useTranslation } from "react-i18next";
import NavbarPractices from "../components/Header/NavbarPracticesPRH";
import Footer from "../components/Footer/Footer";
import BarSelectPracticePRH1 from "../components/Body/PagePractice2PRH/BarSelectPracticeRH1";
import BarSelectPracticePRH2 from "../components/Body/PagePractice2PRH/BarSelectPracticeRH2";

import DI from "../assets/img/logos/PRH.png"

import enjeuxRH from "../assets/img/img/enjeux_RH.png"
import enjeuxRH_EN from "../assets/img/img/enjeux_RH_EN.png"

import warning from "../assets/img/icons/warning.png"

import { Outlet } from "react-router-dom";



export default function PagePracticePRH() {

    const { t } = useTranslation();

    return (

        <div className="maincontainer-pageexpertises" id="PagePRH">
            
            <NavbarPractices/>
            
            <div className="container-pageexpertises">

                <div className="header-pageexpertises">

                    <img src={DI} className="pageexpertises-headerlogo" alt="icon made by ultimatearm from flaticon"></img>
                    <h1>{t('pages.practices.practiceRH.title')}</h1>

                    <div className="header-pageexpertises-text">
                        <p>{t('pages.practices.practiceRH.text')}</p>
                    </div>

                </div>

                <div className="body-pageexpertises">

                    <div className="body-pageexpertises-container" id="enjeux">

                        <h2>{t('pages.practices.practiceRH.enjeux.title')}</h2>
                        <p className="body-pageexpertises-container-enjeux">{t('pages.practices.practiceRH.enjeux.text1')}</p>
                        <br/>
                        <div className="body-pageexpertises-container-img">
                            <img src={enjeuxRH} alt="définition des enjeux de la practice RH"/>
                            <img src={enjeuxRH_EN} alt="définition des enjeux de la practice RH"/>

                        </div>
                        <br/>
                        <p className="body-pageexpertises-container-enjeux">{t('pages.practices.practiceRH.enjeux.text7')}</p>
                        

                    </div>

                    <div className="body-pageexpertises-seperate-RH"></div>

                    <div className="body-pageexpertises-container" id="thematiques">

                        <h2>{t('pages.practices.practiceRH.thematiques.title')}</h2>
                        <fieldset>
                            <legend><img src={warning}  alt="logo warning" id="body-pageexpertises-container-logo"/></legend>

                            <p>{t('pages.practices.practiceRH.thematiques.warning.text1')}</p>

                        </fieldset>
                        <br/>
                        <br/>
                        <p className="body-pageexpertises-container-enjeux">{t('pages.practices.practiceRH.thematiques.text')}</p>

                    </div>


                    <BarSelectPracticePRH1/>
                    <BarSelectPracticePRH2/>
                </div>


            </div>

            <Outlet/>

            <Footer/>

        </div>
        

    );
}

