import {React} from "react";
import "../../../assets/css/domaines.css";
import { useTranslation } from "react-i18next";
import CardsDomaine from "./CardsDomaines";
import FR from "../../../assets/img/logos/finance-risques.png";
import Marketing from "../../../assets/img/logos/marketing.png";
import Achats from "../../../assets/img/logos/achats.png";
import RH from "../../../assets/img/logos/RH.png"

export default function Domaines(){

    const { t } = useTranslation();

    return(

        <div className="domaines-body" id="domaines">
            <h2 id="domaines-titre">
                {t('body.domaines.title')}
            </h2>

            <div className="cards-domaines-maincontainer">
                    
                <CardsDomaine
                    title={t('body.domaines.cards.FinancesRisques.title')}
                    img={FR}
                    text={t('body.domaines.cards.FinancesRisques.text')}
                />
                <div className="cards-domaines-line"></div>
                <CardsDomaine
                    title={t('body.domaines.cards.Marketing.title')}
                    img={Marketing}
                    text={t('body.domaines.cards.Marketing.text')}
                />
               <div className="cards-domaines-line"></div>
                <CardsDomaine
                    title={t('body.domaines.cards.Achats.title')}
                    img={Achats}
                    text={t('body.domaines.cards.Achats.text')}
                />
                <div className="cards-domaines-line"></div>
                <CardsDomaine
                    title={t('body.domaines.cards.RH.title')}
                    img={RH}
                    text={t('body.domaines.cards.RH.text')}
                />

            </div>

        </div>

    )

}