import {React} from "react";
import "../../../assets/css/expertises.css";
import { useTranslation } from "react-i18next";
import CardsExpertise from "./CardsExpertises";

import DataIntelligence from '../../../assets/img/logos/data-intelligence-1.png'
import DigitalExperience from '../../../assets/img/logos/digital-experience-1.png'
import EPM from '../../../assets/img/logos/profit.png'

import { HashLink } from "react-router-hash-link";

export default function Expertises(){

    const { t } = useTranslation();

    return(

        <div className="expertises-body" id="expertises">

            {/*<h2 id="expertises-titre">
                {t('body.expertises.title')}
            </h2>*/}

            <div className="cards-expertises-container">
                <div className="cards-expertises-body">

                    <HashLink smooth to="../../expertises/DataIntelligence/#PageDI" className="cards-expertises-link">
                        <CardsExpertise
                            title={t('body.expertises.cards.DataIntelligence.title')}
                            img={DataIntelligence}
                            text={t('body.expertises.cards.DataIntelligence.text')}
                        />
                    </HashLink>

                    {/*<img src={cloud} className="header-cards-expertises-logo" alt="logo expertises"></img>*/}

                    <HashLink smooth to="../../expertises/EnterprisePerformanceManagement/#PageEPM" className="cards-expertises-link">
                        <CardsExpertise
                            title={t('body.expertises.cards.EPM.title')}
                            img={EPM}
                            text={t('body.expertises.cards.EPM.text')}
                        />
                    </HashLink>

                    {/*<img src={project} className="header-cards-expertises-logo" alt="logo expertises"></img>*/}

                    <HashLink smooth to="../../expertises/DigitalIntelligence/#PageDI" className="cards-expertises-link">
                        <CardsExpertise
                            title={t('body.expertises.cards.DigitalIntelligence.title')}
                            img={DigitalExperience}
                            text={t('body.expertises.cards.DigitalIntelligence.text')}
                        />
                    </HashLink>
                </div>

                {/*<div className="longcard-expertises-body">
                    <img src={Cloud} className="cards-expertises-logo-cloud" alt="logo cloud expertise"/>
                    <h2>Cloud</h2>
                </div>*/}

            </div>
            

        </div>

    )

}