import {React} from "react";
import "../../../assets/css/join.css";
import { useTranslation } from "react-i18next";

export default function JoinC(){

    const { t } = useTranslation();

    return(
        <div className="join-body" id="join">
            <h2 id="join-titre">
                {t('body.join.title')}
            </h2>
            <div id="join-text">
                <p className="join-text-p">
                    {t('body.join.text1')}
                </p>
                <p className="join-text-p">
                    {t('body.join.text2')}  
                </p>
            </div>
        </div>
    )



}