import React, { useState, useRef } from "react";
import "../../assets/css/navbar.css"
import logo_colors from "../../assets/img/logos/logoC_colors.png";
import i18n from '../../i18n/i18n';
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes} from "react-icons/fa";
import {IoIosArrowDown} from "react-icons/io";

import { HashLink } from "react-router-hash-link";


export default function NavbarExpertisesDI(){

    const [navbar, setNavbar] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file. 
    const { t } = useTranslation();
    const navRef = useRef();

    const changeBackground = () => {
        if(window.scrollY >= 50) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    window.addEventListener('scroll', changeBackground);

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
    }

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    }

    const closeNavbar = () => {
        navRef.current.classList.remove("responsive_nav");
    }

    const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return(

        <>

            <nav className={navbar ? "header-navbar-scrolled active" : "header-navbar"} ref={navRef}>

                <div className="header-navbar-logo-holder">
                    <HashLink smooth to="/#"><img src={logo_colors} alt="logo Camondo Consulting"
                    className="header-navbar-logo" id="navbar-logo"/></HashLink>
                </div>

                <div className="header-navbar-menu">

                    <ul className="header-navbar-link">

                        <li className="header-navbar-link-item">
                            <p className="header-navbar-link-item-link">
                                {t('header.navbar.about.title')} <IoIosArrowDown id="navbar-down-icon"/>
                            </p>
                            <div className="navbar-dropdown1">
                                    <div className="navbar-dropdown-item">
                                        <HashLink smooth to="/#" className="navbar-dropdown-inner-title-link"><h4 className="navbar-dropdown-inner-title">{t('header.navbar.about.title1.title')}</h4></HashLink>
                                        <div className="navbar-dropdown-item-list">
                                            <HashLink smooth to="/#expertises" scroll={el => scrollWithOffset(el, -80)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.about.title1.sous-title1')}</p></HashLink>
                                            <HashLink smooth to="/#domaines" scroll={el => scrollWithOffset(el, -45)} className="navbar-dropdown-item-list-link" onClick={closeNavbar} ><p className="navbar-dropdown-item-list-info">{t('header.navbar.about.title1.sous-title2')}</p></HashLink>
                                            <HashLink smooth to="/#clientspartners" scroll={el => scrollWithOffset(el, -20)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.clientspartners')}</p></HashLink>
                                        </div>
                                    </div>
                            </div>
                        </li>

                        <li className="header-navbar-link-item">
                            <p className="header-navbar-link-item-link">
                                {t('header.navbar.expertises.title')} <IoIosArrowDown id="navbar-down-icon"/>
                            </p>
                            <div className="navbar-dropdown2">
                                    <div className="navbar-dropdown-item">
                                        <HashLink smooth to="#" className="navbar-dropdown-inner-title-link"><h4 className="navbar-dropdown-inner-title">{t('header.navbar.expertises.title1.sous-title1.title')}</h4></HashLink>
                                        <div className="navbar-dropdown-item-list">
                                            <HashLink smooth to="DataGovernance/#DG" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.expertises.title1.sous-title1.part1')}</p></HashLink>
                                            <HashLink smooth to="DataIntelligence/#DI" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.expertises.title1.sous-title1.title')}</p></HashLink>
                                            <HashLink smooth to="BusinessAnalytics/#BA" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.expertises.title1.sous-title1.part3')}</p></HashLink>
                                        </div>
                                    </div>
                                    <div className="navbar-dropdown-item">
                                        <HashLink smooth to="../EnterprisePerformanceManagement/#PageEPM" className="navbar-dropdown-inner-title-link"><h4 className="navbar-dropdown-inner-title">{t('header.navbar.expertises.title1.sous-title3.title')}</h4></HashLink>
                                        <div className="navbar-dropdown-item-list">
                                            <HashLink smooth to="../EnterprisePerformanceManagement/#EPM" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.expertises.title1.sous-title3.part1')}</p></HashLink>
                                        </div>
                                    </div>
                                    <div className="navbar-dropdown-item">
                                        <HashLink smooth to="../DigitalIntelligence/#PageDI" className="navbar-dropdown-inner-title-link"><h4 className="navbar-dropdown-inner-title">{t('header.navbar.expertises.title1.sous-title2.title')}</h4></HashLink>
                                        <div className="navbar-dropdown-item-list">
                                            <HashLink smooth to="../DigitalIntelligence/#DI" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.expertises.title1.sous-title2.title')}</p></HashLink>
                                        </div>
                                    </div>
                            </div>
                        </li>

                        <li className="header-navbar-link-item">
                            <p className="header-navbar-link-item-link">
                                {t('header.navbar.practices.title')} <IoIosArrowDown id="navbar-down-icon"/>
                            </p>
                            <div className="navbar-dropdown3">
                                    <div className="navbar-dropdown-item">
                                    <HashLink smooth to="../../practices/PracticeBanque/#PagePB" className="navbar-dropdown-inner-title-link"><h4 className="navbar-dropdown-inner-title">{t('header.navbar.practices.title1.sous-title1.title')}</h4></HashLink>
                                        <div className="navbar-dropdown-item-list">
                                            <HashLink smooth to="../../practices/PracticeBanque/AssetManagement/#AM" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.practices.title1.sous-title1.part1')}</p></HashLink>
                                            <HashLink smooth to="../../practices/PracticeBanque/Compliance/#C" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.practices.title1.sous-title1.part2')}</p></HashLink>
                                            <HashLink smooth to="../../practices/PracticeBanque/CapitalMarket/#CM" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.practices.title1.sous-title1.part3')}</p></HashLink>
                                            <HashLink smooth to="../../practices/PracticeBanque/SecuritiesServices/#SS" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.practices.title1.sous-title1.part4')}</p></HashLink>
                                            <HashLink smooth to="../../practices/PracticeBanque/Finance&Risks/#F&R" scroll={el => scrollWithOffset(el, -175)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.practices.title1.sous-title1.part5')}</p></HashLink>
                                        </div>
                                    </div>
                                    <div className="navbar-dropdown-item">
                                        <HashLink smooth to="../../practices/PracticeRH/#PagePRH" className="navbar-dropdown-inner-title-link"><h4 className="navbar-dropdown-inner-title">{t('header.navbar.practices.title1.sous-title2.title')}</h4></HashLink>
                                        <div className="navbar-dropdown-item-list">
                                            <HashLink smooth to="../../practices/PracticeRH/#enjeux" scroll={el => scrollWithOffset(el, -125)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('pages.practices.practiceRH.enjeux.title')}</p></HashLink>
                                            <HashLink smooth to="../../practices/PracticeRH/#thematiques" scroll={el => scrollWithOffset(el, -125)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('pages.practices.practiceRH.thematiques.title')}</p></HashLink>
                                        </div>
                                    </div>
                                    <div className="navbar-dropdown-item">
                                        <HashLink smooth to="../../practices/PracticePresse/#PagePP" className="navbar-dropdown-inner-title-link"><h4 className="navbar-dropdown-inner-title">{t('header.navbar.practices.title1.sous-title3.title')}</h4></HashLink>
                                        <div className="navbar-dropdown-item-list">
                                            <HashLink smooth to="../../practices/PracticePresse/#PagePP" scroll={el => scrollWithOffset(el, -125)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}><p className="navbar-dropdown-item-list-info">{t('header.navbar.practices.title1.sous-title3.title')}</p></HashLink>
                                        </div>
                                    </div>
                            </div>
                        </li>
                        
                        <li className="header-navbar-link-item header-navbar-link-item-link">
                            <HashLink smooth to="../../career/#career" scroll={el => scrollWithOffset(el, -125)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}>{t('pages.career.title')}</HashLink>
                        </li>

                        <li className="header-navbar-link-item header-navbar-link-item-link">
                            <HashLink smooth to="../../contactus/#contactus" scroll={el => scrollWithOffset(el, -125)} className="navbar-dropdown-item-list-link" onClick={closeNavbar}>{t('header.navbar.contact')}</HashLink>
                        </li>
                        
                        <div className="header-navbar-select">
                            <select defaultValue={selectedLanguage} onChange={chooseLanguage} id="header-navbar-language">
                                <option value="fr" onClick={closeNavbar}>🇫🇷</option>
                                <option value="en" onClick={closeNavbar}>🇬🇧</option>
                            </select>
                        </div>
                        

                        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                            <FaTimes/>
                        </button>
                    </ul>
                </div>

                <button className="nav-btn " onClick={showNavbar}>
                    <FaBars />
                </button>

            </nav>

            

        </>

    )
}
