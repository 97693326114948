import React from "react";
import '../../../assets/css/formcardspage.css';
import { useTranslation } from "react-i18next";

export default function FormContactExpPrac(props){

    const { t } = useTranslation();

    function toggle() {
        document.getElementById("collapse").classList.toggle("hide");
    }

    return(


        <div className="formcontact-container">
            <input type="button" value={t('pages.expertises.expertise3.contact.button')} className="formcontact-button" onClick={toggle}/>

            <div id="collapse" className="show">
                <div className="formcontact-form">

                    <form action="https://formsubmit.co/info@camondoconsulting.fr" method="POST" name="EPM">

                        <input type="hidden" name="_subject" value={props.subject} />
                        <input type="hidden" name="_autoresponse" value={t('pages.expertises.expertise3.contact.text3')}/>
                        <input type="hidden" name="_template" value="box"/>
                        {/*<input type="hidden" name="_next" value="localhost:3000/expertises/EnterprisePerformanceManagement/#PageEPM"/>*/}


                        <div className="formcontact-form-row">

                        
                            <div class="input-data" id="formLastName">
                                <input type="text" name="lastname" required/>
                                <div class="underline"></div>
                                <label for="">{t('pages.contactus.labels.label2')}</label>
                            </div>

                            <div class="input-data" id="formPhone">
                                <input type="tel" name="phone" required/>
                                <div class="underline"></div>
                                <label for="">{t('pages.contactus.labels.label4')}</label>
                            </div>
                            
                        </div>

                        <div className="formcontact-form-row">

                            <div class="input-data" id="formMail">
                                <input type="email" name="email" required/>
                                <div class="underline"></div>
                                <label for="">{t('pages.contactus.labels.label3')}</label>
                            </div>


                        </div>

                        <div className="formcontact-form-row" id="formMessage">

                            <div className="input-data textarea">
                                <textarea rows="20" cols="80" name="message" required/>
                                <div className="underline"></div>
                                <label for="">{t('pages.contactus.labels.label7')}</label>
                            </div>

                        </div>

                        <div className="formcontact-form-row" id="formSubmit">

                            <input type="submit" value={t('pages.expertises.expertise3.contact.envoyer')} className="formcontact-button"/>

                        </div>
                
                    </form>
                </div>
            </div>
        </div>

    )
}