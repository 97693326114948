import React from 'react'
import '../../../assets/css/cardspagepractices.css';
import { useTranslation } from "react-i18next";
import FormContactExpPrac from '../Contact/ContactExpertisesPractices';

export default function CardsPub() {

    const { t } = useTranslation();

  return (
    <div className="cardspagepractices-container" id="Publicité">

        <div className="cardspagepractices-cards cardsanimation">

            <div className="cardspagepractices-cardsheader">

                <h1>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP5.title')}</h1>

            </div>

            <div className="cardspagepractices-cardsbody-presse">

                <div className="cardspagepractices-cardsbody-presse-text">

                    <div className="cardspagepractices-cardsbody-presse-text-text">

                        <p>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP5.text')}</p>

                    </div>

                </div>

                <div className="cardspagepractices-cardsbody-presse-text">

                    <ul>
                        <li>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP5.textlist.textlist1')}</li>
                        <li>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP5.textlist.textlist2')}</li>
                        <li>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP5.textlist.textlist3')}</li>
                    </ul>

                </div>

                <div className="form-practice-presse">
                    <div className="clear"></div>
                    <br/>
                    <p><strong>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP5.contact.text1')}</strong>{t('pages.practices.practicePresse.sub-practicesPresse.sub-practiceP5.contact.text2')}</p>
                    <div className="clear"></div>
                    <div className="pageexpertises-forminfo">
                        <FormContactExpPrac subject="Formulaire Publicité"/>
                    </div>
                    <br/>
                </div>

                <div className='cardspagepractices-cardsbody-presse-text'>
                    <div className="cardspagepractices-cardsbody-presse-text-text">
                        <p>{t('pages.practices.practicePresse.sub-practicesPresse.footer.text1')}</p>
                        <div className='clear'></div>
                        <p>{t('pages.practices.practicePresse.sub-practicesPresse.footer.text2')}</p>
                    </div>
                </div>

            </div>

        </div>

    </div>
  )
}
