import '.././assets/css/App.css';
import Header from '../components/Header/Header';
import BodyContent from '../components/Body/Body';
import Footer from '../components/Footer/Footer';



export default function App() {


  return (
    <>
      
      <Header />
      <BodyContent/>
      <Footer/> 

    </>
        

  );
}

