import React from "react";
import '../../../assets/css/cardspageexpertises.css';
import { useTranslation } from "react-i18next";
import DCJ from "../../../assets/img/logos/DigitalCustomerJourney.png"

export default function CardsDigitalCustomerJourney(){

    const { t } = useTranslation();

    return(

        <div className="cardspageexpertises-container" id="DCJ">

            <div className="cardspageexpertises-cards cardsanimation">

                <div className="cardspageexpertises-cardsheader">

                    <img src={DCJ} className="pageexpertises-logo" alt="icon made by edtim from flaticon"></img>
                    <h1>{t('pages.expertises.expertise2.sub-expertise3.title')}</h1>

                </div>

                <div className="cardspageexpertises-cardsbody">

                    <div className="cardspageexpertises-cardsbody-text">

                        <p>{t('pages.expertises.expertise2.sub-expertise3.parttext.text1')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise2.sub-expertise3.parttext.text2')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise2.sub-expertise3.parttext.text3')}</p>

                    </div>

                    <div className="cardspageexpertises-cardsbody-check">

                        <h3>{t('pages.expertises.expertise2.sub-expertise3.partcheck.checktitle')}</h3>
                        <br/>
                        <ul className="cardspageexpertises-cardsbody-checklist">

                            <li>{t('pages.expertises.expertise2.sub-expertise3.partcheck.checklist1')}</li>
                            <li>{t('pages.expertises.expertise2.sub-expertise3.partcheck.checklist2')}</li>
                            <li>{t('pages.expertises.expertise2.sub-expertise3.partcheck.checklist3')}</li>

                        </ul>

                    </div>

                </div>
                
            </div>

            <div className="cardspageexpertises-seperate"></div>

        </div>

    )

}