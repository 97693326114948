import {React} from "react";
import "../../../assets/css/cardsdomaines.css";

export default function CardsDomaine(props){

    return(

        <div className="cards-domaines">
            <div className="cards-domaines-container">
                <div className="cards-domaines-container1">
                    <div className="cards-domaines-title">
                        <h2>
                            {props.title}
                        </h2>
                    </div>
                    <div className="cards-domaines-logos">
                        <img src={props.img} className="cards-domaines-logo" alt="logo domaines"></img>
                    </div>
                    
                </div>
                <div className="cards-domaines-texts">
                    <p>
                        {props.text}
                    </p>
                </div>
            </div>
        </div>

    )

}