import React from "react";
import '../../../assets/css/cardspageexpertises.css';
import { useTranslation } from "react-i18next";
import EPM from "../../../assets/img/logos/digital-experience-1.png";

import Tagetik from "../../../assets/img/partnerslogos/CCH_Tagetik_Logo.png";
import Board from "../../../assets/img/partnerslogos/Board_Logo.png"
import Anaplan from "../../../assets/img/partnerslogos/Anaplan-logo.png";
import OneStream from "../../../assets/img/partnerslogos/Onestream_Logo.png";
import IBMPlanning from "../../../assets/img/icons/IBM-Planning-Analytics_Logo.png";
import OracleHyperion from "../../../assets/img/icons/oracle-hyperion_Logo.png";
import FormContactExpPrac from "../Contact/ContactExpertisesPractices";


export default function CardsDigitalIntelligence(){

    const { t } = useTranslation();


    return(

        <div className="cardspageexpertises-container" id="DI">

            <div className="cardspageexpertises-cards cardsanimation">

                <div className="cardspageexpertises-cardsheader">
                    <img src={EPM} className="pageexpertises-logo" alt="icon made by parzival from flaticon"></img>
                    
                    <h1>{t('pages.expertises.expertise2.title')}</h1>

                </div>

                <div className="cardspageexpertises-cardsbody">

                    <div className="cardspageexpertises-cardsbody-text">

                        <p>{t('pages.expertises.expertise2.text1')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise2.text2')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise2.text3')}</p>
                        
                        <br/>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise2.contact.text1')}</strong>{t('pages.expertises.expertise2.contact.text2')}</p>
                        <br/>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Digital Intelligence"/>
                        </div>
                        <br/>
                        

                        <br/>
                        <p>
                            <strong>{t('pages.expertises.expertise3.sub-expertise1.keywords.title')}</strong>
                            {t('pages.expertises.expertise2.keywords.keyword1')}
                            {t('pages.expertises.expertise2.keywords.keyword2')}
                            {t('pages.expertises.expertise2.keywords.keyword3')}
                            {t('pages.expertises.expertise2.keywords.keyword4')}
                            {t('pages.expertises.expertise2.keywords.keyword5')}
                        </p>

                    </div>

                    {/*<div className="cardspageexpertises-cardsbody-check">

                        <h3>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checktitle')}</h3>
                        <br/>
                        <ul className="cardspageexpertises-cardsbody-checklist">

                            <li>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checklist1')}</li>
                            <li>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checklist2')}</li>
                            <li>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checklist3')}</li>

                        </ul>

                    </div>*/}

                </div>
                
            </div>

            <div className="cardspageexpertises-seperate"></div>

        </div>
    )

}