import React from 'react';
import '.././assets/css/mentionslegales.css';
import { useTranslation } from "react-i18next";

import NavbarMentions from '../components/Header/NavbarMentions';
import Footer from '../components/Footer/Footer';

export default function MentionsLegales() {

    const { t } = useTranslation();

    return (

        <div className="pagementions" id="mentions">

            <NavbarMentions/>

            <div className="pagecareer-container-body">

                <div className="header-pagecareer">

                    <h1>{t('footer.mentions.mentions.title')}</h1>

                </div>

                <div className="body-pagementions">

                    <div className="body-pagementions-container">
                        
                        <p className="body-pagementions-text">{t('footer.mentions.mentions.text')}</p>

                        <div className="body-pagementions-container-text">

                            <h2 className="body-pagementions-container-title"><strong>{t('footer.mentions.mentions.part1.title1')}</strong>{t('footer.mentions.mentions.part1.title2')}</h2>

                            <div className="body-pagementions-container-infos">

                                <ul className="body-pagementions-container-list">
                                    <li><strong>{t('footer.mentions.mentions.part1.name.name')}</strong>{t('footer.mentions.mentions.part1.name.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.form.name')}</strong>{t('footer.mentions.mentions.part1.form.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.RCS.name')}</strong>{t('footer.mentions.mentions.part1.RCS.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.headquarter.name')}</strong>{t('footer.mentions.mentions.part1.headquarter.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.creator.name')}</strong>{t('footer.mentions.mentions.part1.creator.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.manager.name')}</strong>{t('footer.mentions.mentions.part1.manager.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.webmaster.name')}</strong>{t('footer.mentions.mentions.part1.webmaster.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.contact.name')}</strong>{t('footer.mentions.mentions.part1.contact.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part1.tel.name')}</strong>{t('footer.mentions.mentions.part1.tel.text')}</li>
                                </ul>

                            </div>

                        </div>

                        <div className="body-pagementions-container-text">

                            <h2 className="body-pagementions-container-title"><strong>{t('footer.mentions.mentions.part2.title1')}</strong>{t('footer.mentions.mentions.part2.title2')}</h2>

                            <div className="body-pagementions-container-infos">

                                <ul className="body-pagementions-container-list">
                                    <li><strong>{t('footer.mentions.mentions.part2.name.name')}</strong>{t('footer.mentions.mentions.part2.name.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part2.form.name')}</strong>{t('footer.mentions.mentions.part2.form.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part2.RCS.name')}</strong>{t('footer.mentions.mentions.part2.RCS.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part2.siret.name')}</strong>{t('footer.mentions.mentions.part2.siret.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part2.APE.name')}</strong>{t('footer.mentions.mentions.part2.APE.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part2.TVA.name')}</strong>{t('footer.mentions.mentions.part2.TVA.text')}</li>
                                    <li><strong>{t('footer.mentions.mentions.part2.headquarter.name')}</strong>{t('footer.mentions.mentions.part2.headquarter.text')}</li>
                                </ul>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <Footer/>

        </div>
    )
}
