import React from "react";
import '../../../assets/css/barselectexpertisespractices1.css';
import { useTranslation } from "react-i18next";

import { HashLink } from "react-router-hash-link";

export default function BarSelectPracticePRH1(){

    const { t } = useTranslation();

    const linkExpertise = document.querySelectorAll('.boxexpertises_container_link1');

    linkExpertise.forEach(link => {
        link.addEventListener('click', function() {
            document.querySelector('.activeexpertise1')?.classList.remove('activeexpertise1');
            this.classList.add('activeexpertise1');
        })
    })

    const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return(

        <div className="barselectexpertises-container1">

                <ul className="boxexpertises-maincontainer1">

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="Effectif/#Effectif" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="linkDataGovernance">
                                <h4>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH1.title')}</h4>
                            </HashLink>
                        </div>
                    </li>

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="Comp&Ben/#CompBen" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="BIBD">
                                <h4>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH2.title')}</h4>
                            </HashLink>
                        </div>
                    </li>

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="MouvementduPersonnel/#MouvPerso" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="BusinessAna" >
                                <h4>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH3.title')}</h4>
                            </HashLink>
                        </div>
                    </li>

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="Carriere/#Carriere" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="ERCM">
                                <h4>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH4.title')}</h4>
                            </HashLink>
                        </div>
                    </li>

                </ul>

        </div>

    )

}