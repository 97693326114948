import React from "react";
import '../../../assets/css/cardspagepractices.css';
import { useTranslation } from "react-i18next";
import security from "../../../assets/img/logos/security.png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

export default function CardsSecuritiesServices(){

    const { t } = useTranslation();

    return(

        <div className="cardspagepractices-container" id="SS">

            <div className="cardspagepractices-cards cardsanimation">

                <div className="cardspagepractices-cardsheader">

                    <img src={security} className="pagepractices-logo" alt="icon made by smashingstocks from flaticon"></img>
                    <h1>{t('pages.practices.practice1.sub-practice4.title')}</h1>

                </div>

                <div className="cardspagepractices-cardsbody">

                    <div className="cardspagepractices-cardsbody-text1">

                        <p>{t('pages.practices.practice1.sub-practice4.parttext1.text1')}</p>
                        <div className="clear"></div>
                        <br/>
                        <p>{t('pages.practices.practice1.sub-practice4.parttext1.text2')}</p>
                        <br/>
                        <div className="clear"></div>
                        <p>{t('pages.practices.practice1.sub-practice4.textlist.text')}</p>
                        <ul className="cardspagepractices-cardsbody-textlist">

                            <li>{t('pages.practices.practice1.sub-practice4.textlist.textlist1')}</li>
                            <li>{t('pages.practices.practice1.sub-practice4.textlist.textlist2')}</li>
                            <li>{t('pages.practices.practice1.sub-practice4.textlist.textlist3')}</li>
                            <li>{t('pages.practices.practice1.sub-practice4.textlist.textlist4')}</li>
                            <li>{t('pages.practices.practice1.sub-practice4.textlist.textlist5')}</li>

                        </ul>

                        <div className="clear"></div>
                        <br/>
                        <p><strong>{t('pages.practices.practice1.sub-practice4.contact.text1')}</strong>{t('pages.practices.practice1.sub-practice4.contact.text2')}</p>
                        <div className="clear"></div>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Securities Services"/>
                        </div>
                        <br/>

                    </div>

                </div>
                
            </div>

            <div className="cardspagepractices-seperate"></div>

        </div>

    )

}