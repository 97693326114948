import React from "react";
import '../../../assets/css/cardspageexpertises.css';
import { useTranslation } from "react-i18next";
import EPM from "../../../assets/img/logos/profit.png";

import Tagetik from "../../../assets/img/partnerslogos/CCH_Tagetik_Logo.png";
import Board from "../../../assets/img/partnerslogos/Board_Logo.png"
import Anaplan from "../../../assets/img/partnerslogos/Anaplan-logo.png";
import OneStream from "../../../assets/img/partnerslogos/Onestream_Logo.png";
import IBMPlanning from "../../../assets/img/icons/IBM-Planning-Analytics_Logo.png";
import OracleHyperion from "../../../assets/img/icons/oracle-hyperion_Logo.png";
import FormContactExpPrac from "../Contact/ContactExpertisesPractices";


export default function CardsEntreprisePerformance(){

    const { t } = useTranslation();


    return(

        <div className="cardspageexpertises-container" id="EPM">

            <div className="cardspageexpertises-cards cardsanimation">

                <div className="cardspageexpertises-cardsheader">
                    <img src={EPM} className="pageexpertises-logo" alt="icon made by parzival from flaticon"></img>
                    
                    <h1>{t('pages.expertises.expertise3.sub-expertise1.title')}</h1>

                </div>

                <div className="cardspageexpertises-cardsbody">

                    <div className="cardspageexpertises-cardsbody-text">

                        <p>{t('pages.expertises.expertise3.sub-expertise1.parttext1.text1')}</p>

                        <ul className="cardspageexpertises-cardsbody-textlist">

                            <li>{t('pages.expertises.expertise3.sub-expertise1.parttext1.text2')}</li>
                            <li>{t('pages.expertises.expertise3.sub-expertise1.parttext1.text3')}</li>
                            <li>{t('pages.expertises.expertise3.sub-expertise1.parttext1.text4')}</li>
                            <li>{t('pages.expertises.expertise3.sub-expertise1.parttext1.text5')}</li>

                        </ul>

                        <br/>
                        <p>
                            {t('pages.expertises.expertise3.sub-expertise1.parttext2.text1')}
                            <strong>{t('pages.expertises.expertise3.sub-expertise1.parttext2.text2')}</strong>
                            {t('pages.expertises.expertise3.sub-expertise1.parttext2.text3')}
                            <strong>{t('pages.expertises.expertise3.sub-expertise1.parttext2.text4')}</strong>
                            {t('pages.expertises.expertise3.sub-expertise1.parttext2.text5')}
                        </p>
                        <br/>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise3.contact.text1')}</strong>{t('pages.expertises.expertise3.contact.text2')}</p>
                        <br/>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire EPM"/>
                        </div>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise3.sub-expertise1.parttext2.text6')}</strong></p>


                        <div className="cardspageexpertises-cardsbody-check-logo">
                            <img className="pageexpertises-check-logo" src={Tagetik} alt="logo Tagetik"/>
                            <img className="pageexpertises-check-logo" src={Board} alt="logo Board"/>
                            <img className="pageexpertises-check-logo" src={Anaplan} alt="logo Anaplan"/>
                            <img className="pageexpertises-check-logo" src={OneStream} alt="logo Onestream"/>
                            <img className="pageexpertises-check-logo" src={IBMPlanning} alt="logo IBM Planning Analytics"/>
                            <img className="pageexpertises-check-logo" src={OracleHyperion} alt="logo Oracle Hyperion"/>
                            <p>. . .</p>
                        </div>

                        <br/>
                        <p>
                            <strong>{t('pages.expertises.expertise3.sub-expertise1.keywords.title')}</strong>
                            {t('pages.expertises.expertise3.sub-expertise1.keywords.keyword1')}
                            {t('pages.expertises.expertise3.sub-expertise1.keywords.keyword2')}
                            {t('pages.expertises.expertise3.sub-expertise1.keywords.keyword3')}
                            {t('pages.expertises.expertise3.sub-expertise1.keywords.keyword4')}
                        </p>

                    </div>

                    {/*<div className="cardspageexpertises-cardsbody-check">

                        <h3>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checktitle')}</h3>
                        <br/>
                        <ul className="cardspageexpertises-cardsbody-checklist">

                            <li>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checklist1')}</li>
                            <li>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checklist2')}</li>
                            <li>{t('pages.expertises.expertise3.sub-expertise1.partcheck.checklist3')}</li>

                        </ul>

                    </div>*/}

                </div>
                
            </div>

            <div className="cardspageexpertises-seperate"></div>

        </div>
    )

}