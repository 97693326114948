import React from "react";
import "../../assets/css/footer.css";
import { useTranslation } from "react-i18next";

import logoCamondo from "../../assets/img/logos/logoC_white.png"
import logoC from "../../assets/img/logos/logo_white_C.png"
import LinkedIn from "../../assets/img/logos/linkedin.png"
import Localisation from "../../assets/img/icons/localisateur.png"

import { HashLink } from "react-router-hash-link";


export default function Footer(){

    const { t } = useTranslation();

    const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return(

        <div id="footer">

            <div className="footer-container">

                <div id="footer-header">

                    <img src={logoCamondo} alt="logo Camondo Consulting" id="logoCamondo"/>
                    <div className="footer-header-loc">
                        <img src={Localisation} id="footer-header-loc-icon" alt="logo localisation"/>
                        <p>165 Avenue Charles de Gaulle, 92200 Neuilly-sur-Seine</p>
                    </div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.684005276926!2d2.261025175124261!3d48.88330019895497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6656eb34ef17d%3A0x3410fd4e62a19e82!2s165%20Av.%20Charles%20de%20Gaulle%2C%2092200%20Neuilly-sur-Seine!5e0!3m2!1sfr!2sfr!4v1685001722686!5m2!1sfr!2sfr" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Adresse Camondo Consulting" id="footer-map" />

                </div>

                <div id="footer-body">

                    <div className="footer-body-div">

                        <h2 id="footer-body-contact-titre">
                            {t('footer.contact.title')}
                        </h2>

                        <div className="footer-body-line"></div>

                        <div id="footer-body-contact-infos">
                            <p className="footer-body-infos">{t('footer.contact.phone')}</p>
                            <p className="footer-body-infos"><a href="mailto:info@camondoconsulting.fr">{t('footer.contact.email')}</a></p>
                        </div>

                    </div>

                    <div className="footer-body-div">

                        <h2 id="footer-body-contact-titre">
                            {t('footer.about.title')}
                        </h2>

                        <div className="footer-body-line"></div>

                        <div id="footer-body-contact-infos">
                            <p className="footer-body-infos"><HashLink smooth to="/#expertises" scroll={el => scrollWithOffset(el, -80)} className="footer-body-infos-link">{t('footer.about.sous-title2')}</HashLink></p>
                            <p className="footer-body-infos"><HashLink smooth to="/#domaines" scroll={el => scrollWithOffset(el, -45)} className="footer-body-infos-link">{t('footer.about.sous-title3')}</HashLink></p>
                            <p className="footer-body-infos"><HashLink smooth to="/#clientspartners" scroll={el => scrollWithOffset(el, -20)} className="footer-body-infos-link">{t('footer.about.sous-title4')}</HashLink></p>
                        </div>

                    </div>

                    <div className="footer-body-div">

                        <h2 id="footer-body-contact-titre">
                            {t('footer.follow')}
                        </h2>

                        <div className="footer-body-line"></div>

                        <div id="footer-body-contact-infos">
                            <a href="https://www.linkedin.com/company/camondo-consulting" target="_blank" rel="noreferrer" className="footer-body-infos-link"><img src={LinkedIn} alt="logo linkedin camondo consulting" id="footer-logo"/></a>
                        </div>

                    </div>

                </div>
            
            </div>

            

            <div id="footer-footer">

                <p id="footer-footer-mentions">
                    {t('footer.mentions.enterprise')} 2023  | {t('footer.mentions.droits')}  |  <HashLink smooth to="/mentionslegales/#mentions" className="footer-footer-mentions-link">{t('footer.mentions.mentions.title')}</HashLink>
                </p>
                <img src={logoC} alt="logo Camondo Consulting" id="footer-logoC"/>

            </div>


        </div>

    )

}