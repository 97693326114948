import React from "react";
import '../../../assets/css/cardspageexpertises.css';
import { useTranslation } from "react-i18next";
import DI from "../../../assets/img/logos/BusinessIntelligence.png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

import DF from "../../../assets/img/partnerslogos/Azure-Data-Factory_Logo.png"
import Talend from "../../../assets/img/partnerslogos/Talend_Logo.png"
import Datastage from "../../../assets/img/partnerslogos/Datastage_IBM_Logo.png"
import Informatica from "../../../assets/img/partnerslogos/Informatica_Logo.png"
import DataIntegrator from "../../../assets/img/partnerslogos/Dataintegrator_Oracle8logo.jpg"

import Oracle from "../../../assets/img/partnerslogos/Oracle-Logo.png"
import Teradata from "../../../assets/img/partnerslogos/Teradata_logo.png"
import DB2 from "../../../assets/img/partnerslogos/IBM_DB2_Logo.png"

import Snowflake from "../../../assets/img/partnerslogos/Snowflake_Logo.svg.png"
import Hadoop from "../../../assets/img/partnerslogos/Hadoop_Logo.png"

import BI from "../../../assets/img/partnerslogos/PowerBI-Logo.png"
import Tableau from "../../../assets/img/partnerslogos/Tableau_Logo.png"
import QlikSense from "../../../assets/img/partnerslogos/QlikSense_Logo.png"

export default function CardsDI(){

    const { t } = useTranslation();

    return(

        <div className="cardspageexpertises-container" id="DI">

            <div className="cardspageexpertises-cards cardsanimation">

                <div className="cardspageexpertises-cardsheader">
                    <img src={DI} className="pageexpertises-logo" alt="icon made by xnimrodx from flaticon"></img>
                    
                    <h1>{t('pages.expertises.expertise1.sub-expertise4.title')}</h1>

                </div>

                <div className="cardspageexpertises-cardsbody">

                    <div className="cardspageexpertises-cardsbody-text">

                        <p>{t('pages.expertises.expertise1.sub-expertise4.parttext.text1')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise1.sub-expertise4.parttext.text2')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise1.sub-expertise4.parttext.text3')}<strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text4')}</strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text5')}
                        <strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text6')}</strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text7')}<strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text8')}</strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text9')}</p>
                        <br/>
                        <p>{t('pages.expertises.expertise1.sub-expertise4.parttext.text10')}</p>
                        <br/>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise3.contact.text1')}</strong>{t('pages.expertises.expertise1.sub-expertise3.contact.text2')}</p>
                        <br/>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Data Intelligence"/>
                        </div>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text11')}</strong></p>
                        <div className="cardspageexpertises-cardsbody-check-logo">
                            <img className="pageexpertises-check-logo" src={DF} alt="logo Data Factory Azure"/>
                            <img className="pageexpertises-check-logo" src={Talend} alt="logo Talend"/>
                            <img className="pageexpertises-check-logo" src={Datastage} alt="logo Datastage"/>
                            <img className="pageexpertises-check-logo" src={Informatica} alt="logo Informatica"/>
                            <img className="pageexpertises-check-logo" src={DataIntegrator} alt="logo DataIntegrator"/>
                            <p>. . .</p>
                        </div>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text12')}</strong></p>
                        <div className="cardspageexpertises-cardsbody-check-logo">
                            <img className="pageexpertises-check-logo" src={Oracle} alt="logo Oracle"/>
                            <img className="pageexpertises-check-logo" src={Teradata} alt="logo Teradata"/>
                            <img className="pageexpertises-check-logo" src={DB2} alt="logo IBM DB2"/>
                            <p>. . .</p>
                        </div>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text13')}</strong></p>
                        <div className="cardspageexpertises-cardsbody-check-logo">
                            <img className="pageexpertises-check-logo" src={Snowflake} alt="logo Snowflake"/>
                            <img className="pageexpertises-check-logo" src={Hadoop} alt="logo Hadoop"/>
                            <p>. . .</p>
                        </div>
                        <br/>
                        <p><strong>{t('pages.expertises.expertise1.sub-expertise4.parttext.text14')}</strong></p>
                        <div className="cardspageexpertises-cardsbody-check-logo">
                            <img className="pageexpertises-check-logo" src={BI} alt="logo Power BI"/>
                            <img className="pageexpertises-check-logo" src={Tableau} alt="logo Tableau"/>
                            <img className="pageexpertises-check-logo" src={QlikSense} alt="logo QlikSense"/>
                            <p>. . .</p>
                        </div>

                        <br/>
                        <p>
                            <strong>{t('pages.expertises.expertise3.sub-expertise1.keywords.title')}</strong>
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword1')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword2')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword4')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword5')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword6')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword7')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword8')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword9')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword10')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword11')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword12')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword13')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword14')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword15')}
                            {t('pages.expertises.expertise1.sub-expertise4.keywords.keyword16')}
                        </p>

                    </div>

                    

                </div>
                
            </div>

            <div className="cardspageexpertises-seperate"></div>

        </div>

    )

}