import React from "react";
import '.././assets/css/pageexpertisespractices.css';
import { useTranslation } from "react-i18next";
import NavbarExpertises from "../components/Header/NavbarExpertiseEPM";
import Footer from "../components/Footer/Footer";


import DI from "../assets/img/logos/profit.png"

import { Outlet } from "react-router-dom";
import CardsEntreprisePerformance from "../components/Body/PageExpertise3EPM/EntreprisePerformance";


export default function PageExpertise3() {

    const { t } = useTranslation();

    return (

        <div className="maincontainer-pageexpertises" id="PageEPM">
            
            <NavbarExpertises/>
            
            <div className="container-pageexpertises">

                <div className="header-pageexpertises">

                    <img src={DI} className="pageexpertises-headerlogo" alt="icon made by iconixar from flaticon"></img>
                    <h1>{t('pages.expertises.expertise3.title')}</h1>

                    <div className="header-pageexpertises-text">
                        <p>{t('pages.expertises.expertise3.text')}</p>
                    </div>

                </div>

                <CardsEntreprisePerformance/>


            </div>

            <Outlet/>

            <Footer/>

        </div>
        

    );
}

