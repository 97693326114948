import React from "react";
import '../../../assets/css/cardspagepractices.css';
import { useTranslation } from "react-i18next";
import capital from "../../../assets/img/logos/capital.png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

export default function CardsCapitalMarket(){

    const { t } = useTranslation();

    return(

        <div className="cardspagepractices-container" id="CM">

            <div className="cardspagepractices-cards cardsanimation">

                <div className="cardspagepractices-cardsheader">

                    <img src={capital} className="pagepractices-logo" alt="icon made by eucalyp from flaticon"></img>
                    <h1>{t('pages.practices.practice1.sub-practice3.title')}</h1>

                </div>

                <div className="cardspagepractices-cardsbody">

                    <div className="cardspagepractices-cardsbody-text1">

                        <p>{t('pages.practices.practice1.sub-practice3.textlist.text')}</p>
                        <br/>
                        <ul className="cardspagepractices-cardsbody-textlist">

                            <li>{t('pages.practices.practice1.sub-practice3.textlist.textlist1')}</li>
                            <li>{t('pages.practices.practice1.sub-practice3.textlist.textlist2')}</li>
                            <li>{t('pages.practices.practice1.sub-practice3.textlist.textlist3')}</li>
                            <li>{t('pages.practices.practice1.sub-practice3.textlist.textlist4')}</li>
                            <li>{t('pages.practices.practice1.sub-practice3.textlist.textlist5')}</li>

                        </ul>

                        <div className="clear"></div>
                        <br/>
                        <p><strong>{t('pages.practices.practice1.sub-practice3.contact.text1')}</strong>{t('pages.practices.practice1.sub-practice3.contact.text2')}</p>
                        <div className="clear"></div>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Capital Market"/>
                        </div>
                        <br/>

                    </div>

                </div>
                
            </div>

            <div className="cardspagepractices-seperate"></div>

        </div>

    )

}