import {React} from "react";
import Navbar from "./Navbar";
import "../../assets/css/header.css"
import { useTranslation } from "react-i18next";

import Expertises from "../Body/Expertises/Expertises";

export default function Header(){

    const { t } = useTranslation();

    return(

        <div className="header" id="header">

            <Navbar />
            
            <div className="header-title">
                {/*<h1 className="title">
                    {t('header.main-title')}
                </h1><br/>*/}

                    
                {/*<fieldset className="class-header-title">
                    <HashLink smooth to="../../expertises/DataIntelligence/#PageDI" className="class-header-title-link">DATA INTELLIGENCE</HashLink>
                    <p>|</p>
                    <h1>E . P . M</h1>
                    <p>|</p>
                    <HashLink smooth to="../../expertises/DigitalExperience/#PageDE" className="class-header-title-link">DIGITAL EXPERIENCE</HashLink>
                    <legend><HashLink className="class-header-title-link"><div className="class-header-title2">CLOUD</div></HashLink><div className="class-header-title2">  |  PROJECT MANAGEMENT</div></legend>
                </fieldset>*/}

                <Expertises/>
                
                
                <h2 className="title">
                    {t('header.title')}<br/>
                    {t('header.title2')}
                </h2>
            </div>

            {/*<Link to="clientspartners" spy={true} smooth={true} offset={0} duration={800}>
                <div className="arrow"></div>
            </Link>*/}
            

        </div>
        

    )

}