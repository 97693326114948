import React from "react";
import '../../../assets/css/cardspagepractices.css';
import { useTranslation } from "react-i18next";

import shooter from "../../../assets/img/icons/shooter.png"
import gauge from "../../../assets/img/icons/gauge-chart.png"

import FormContactExpPrac from "../Contact/ContactExpertisesPractices";

export default function CardsAbsenteisme(){

    const { t } = useTranslation();

    return(

        <div className="cardspagepractices-container" id="Absenteisme">

            <div className="cardspagepractices-cards cardsanimation">

                <div className="cardspagepractices-cardsheader">

                    <h1>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.title')}</h1>

                </div>

                <div className="cardspagepractices-cardsbody">

                    <div className="cardspagepractices-cardsbody-text">

                        <div className="cardspagepractices-cardsbody-img">
                            <img src={shooter} alt="logo cible" className="cardspagepractices-cardsbody-img-img"/>
                        </div>

                        <div className="cardspagepractices-cardsbody-text-text">
                            <p>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.enjeux.text1')}<strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.enjeux.text2')}</strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.enjeux.text3')}<strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.enjeux.text4')}</strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.enjeux.text5')}<strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.enjeux.text6')}</strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.enjeux.text7')}</p>
                        </div>

                    </div>

                    <div className="cardspagepractices-cardsbody-seperate"></div>

                    <div className="cardspagepractices-cardsbody-text">

                        <div className="cardspagepractices-cardsbody-img">
                            <img src={gauge} alt="logo cible" className="cardspagepractices-cardsbody-img-img"/>
                        </div>

                        <div className="cardspagepractices-cardsbody-text-text">
                            <p>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.axes.text1')}</p>
                            <ul>
                                <li>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.axes.textlist1')}</li>
                                <div class="clear"></div>
                                <li>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.axes.textlist2')}</li>
                            </ul>
                        </div>

                    </div>

                    <div className="form-practice">
                        <div className="clear"></div>
                        <br/>
                        <p><strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.contact.text1')}</strong>{t('pages.practices.practiceRH.sub-practicesRH.sub-practiceRH5.contact.text2')}</p>
                        <div className="clear"></div>
                        <div className="pageexpertises-forminfo">
                            <FormContactExpPrac subject="Formulaire Absentéisme"/>
                        </div>
                        <br/>
                    </div>

                </div>
                
            </div>


        </div>

    )

}