import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import PageContact from './pages/PageContact';
import PageCarriere from './pages/PageCarriere';
import MentionsLegales from './pages/MentionsLegales';

import PageExpertise1 from './pages/PageExpertise1DI';
import CardsDataGovernance from './components/Body/PageExpertise1DI/DataGovernance';
import CardsDI from './components/Body/PageExpertise1DI/DataIntelligence';
import CardsBusinessAnalytics from './components/Body/PageExpertise1DI/BusinessAnalytics';

import PageExpertiseDigitalIntelligence from './pages/PageExpertiseDigitalIntelligence';
import PageExpertise2 from './pages/PageExpertise2DE';
import CardsDigitalAnalytics from './components/Body/PageExpertise2DE/DigitalAnalytics';
import CardsDigitalMarketing from './components/Body/PageExpertise2DE/DigitalMarketing';
import CardsDigitalCustomerJourney from './components/Body/PageExpertise2DE/DigitalCustomerJourney';

import PageExpertise3 from './pages/PageExpertise3EPM';


import PagePracticePB from './pages/PagePractice1PB';
import CardsAssetManagament from './components/Body/PagePractice1PB/AssetManagement';
import CardsCompliance from './components/Body/PagePractice1PB/Compliance';
import CardsCapitalMarket from './components/Body/PagePractice1PB/CapitalMarket';
import CardsSecuritiesServices from './components/Body/PagePractice1PB/SecuritiesServices';
import CardsFinanceRisks from './components/Body/PagePractice1PB/Finance&Risks';

import PagePracticePRH from './pages/PagePractice2PRH';
import CardsEffectif from './components/Body/PagePractice2PRH/Effectif';
import CardsCompBen from './components/Body/PagePractice2PRH/Comp&Ben';
import CardsMouvPerso from './components/Body/PagePractice2PRH/MouvPerso';
import CardsCarriere from './components/Body/PagePractice2PRH/Carriere';
import CardsAbsenteisme from './components/Body/PagePractice2PRH/Absenteisme';
import CardsFormation from './components/Body/PagePractice2PRH/Formation';
import CardsRecrutement from './components/Body/PagePractice2PRH/Recrutement';
import CardsLegal from './components/Body/PagePractice2PRH/Legal';

import PagePracticePP from './pages/PagePractice3PP';
import CardsAbonnement from './components/Body/PagePractice3PP/Abonnement';
import CardsVenteNum from './components/Body/PagePractice3PP/VenteNum';
import CardsDiffNum from './components/Body/PagePractice3PP/DiffNum';
import CardsTirageFab from './components/Body/PagePractice3PP/TirageFab';
import CardsPub from './components/Body/PagePractice3PP/Pub';




const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: "/contactus",
    element: <PageContact/>
  },
  {
    path: "/career",
    element: <PageCarriere/>
  },
  {
    path: "/mentionslegales",
    element: <MentionsLegales/>
  },
  {
    path:"expertises",
    children: [
      {
        path: "DataIntelligence",
        element: <PageExpertise1/>,
        children: [
          {
            path:"DataGovernance",
            element: <CardsDataGovernance/>
          },
          {
            path:"DataIntelligence",
            element: <CardsDI/>
          },
          {
            path:"BusinessAnalytics",
            element: <CardsBusinessAnalytics/>
          }
        ],
      },
      {
        path:"DigitalExperience",
        element: <PageExpertise2/>,
        children: [
          {
            path:"DigitalAnalytics",
            element: <CardsDigitalAnalytics/>
          },
          {
            path:"DigitalMarketing",
            element: <CardsDigitalMarketing/>
          },
          {
            path:"DigitalCustomerJourney",
            element: <CardsDigitalCustomerJourney/>
          }
        ]
      },
      {
        path:"DigitalIntelligence",
        element: <PageExpertiseDigitalIntelligence/>
      },
      {
        path:"EnterprisePerformanceManagement",
        element: <PageExpertise3/>
      }
    ]
  },
  {
    path:"practices",
    children: [
      {
        path: "PracticeBanque",
        element: <PagePracticePB/>,
        children: [
          {
            path:"AssetManagement",
            element: <CardsAssetManagament/>
          },
          {
            path:"Compliance",
            element: <CardsCompliance/>
          },
          {
            path:"CapitalMarket",
            element: <CardsCapitalMarket/>
          },
          {
            path:"SecuritiesServices",
            element: <CardsSecuritiesServices/>
          },
          {
            path:"Finance&Risks",
            element: <CardsFinanceRisks/>
          }
        ],
      },
      {
        path:"PracticeRH",
        element: <PagePracticePRH/>,
        children: [
          {
            path:"Effectif",
            element: <CardsEffectif/>
          },
          {
            path:"Comp&Ben",
            element: <CardsCompBen/>
          },
          {
            path:"MouvementduPersonnel",
            element: <CardsMouvPerso/>
          },
          {
            path:"Carriere",
            element: <CardsCarriere/>
          },
          {
            path:"Absenteisme",
            element: <CardsAbsenteisme/>
          },
          {
            path:"Formation",
            element: <CardsFormation/>
          },
          {
            path:"Recrutement",
            element: <CardsRecrutement/>
          },
          {
            path:"Legal",
            element: <CardsLegal/>
          }

        ]
      },
      {
        path:"PracticePresse",
        element: <PagePracticePP/>,
        children: [
          {
            path:"Abonnement",
            element: <CardsAbonnement/>
          },
          {
            path:"VenteNuméro",
            element: <CardsVenteNum/>
          },
          {
            path:"DiffusionNumérique",
            element: <CardsDiffNum/>
          },
          {
            path:"Tirage&Fabrication",
            element: <CardsTirageFab/>
          },
          {
            path:"Publicité",
            element: <CardsPub/>
          },
        ]
      }
    ]
  },
  
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>

      <RouterProvider router={router}>

      </RouterProvider>

    </Suspense>
  </React.StrictMode>
);
 
reportWebVitals();
